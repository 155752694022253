import type { ErrorBag } from '../business-model/form';
import { checkIsConditionMet, FieldTypeIds } from '../fields';
import { App, GatherField, InputValue } from '../gather';
import { validateEmail } from '../utils';
import { checkIsInputValueEmpty } from './input-value';

export interface GatherInputValidation {
  isValid: () => boolean;
  getErrors: () => string[];
  getFirstError: () => string | null;
  getFirstFieldLabel: () => string | null;
  errorBag: ErrorBag;
}

function createInputValidation(errorBag: Record<string, string[]>) {
  const validation: GatherInputValidation = {
    errorBag,
    isValid() {
      return this.getErrors().length === 0;
    },
    getErrors() {
      return Object.values(this.errorBag).flat();
    },
    getFirstError() {
      return this.getErrors()[0] ?? null;
    },
    getFirstFieldLabel() {
      return Object.keys(this.errorBag)[0] ?? null;
    },
  };

  return validation;
}

export function validateGatherInput(
  field: GatherField,
  inputValue: InputValue,
  inputValues: InputValue[]
): GatherInputValidation {
  const errorBag: ErrorBag = {};

  function fieldError(field, errorMessage) {
    if (!errorBag[field.label]) {
      errorBag[field.label] = [];
    }
    errorBag[field.label].push(errorMessage);
  }

  if (
    field.is_required &&
    checkIsConditionMet(field, inputValues, inputValue.template_section_index)
  ) {
    if (checkIsInputValueEmpty(inputValue, field)) {
      fieldError(field, field.label + ' is required');
    }
  }

  if (field.field_type_id === FieldTypeIds.TEXT) {
    if (
      field.options?.validation === 'email' &&
      inputValue.value &&
      !validateEmail(inputValue.value)
    ) {
      fieldError(field, field.label + ' must be a valid email address');
    }
  }

  return createInputValidation(errorBag);
}

export function validateAppWithInputValues(
  app: App,
  inputValues: InputValue[],
  whitelistedSectionIds: number[] | null = null
): GatherInputValidation {
  for (let inputValue of inputValues) {
    const templateField = app.sections
      ?.flatMap((s) => s.template_fields)
      .find((t) => t?.id === inputValue.template_field_id);

    if (
      !templateField ||
      (whitelistedSectionIds &&
        !whitelistedSectionIds.includes(templateField.template_section_id))
    ) {
      continue;
    }

    const validation = validateGatherInput(
      templateField,
      inputValue,
      inputValues
    );
    if (!validation.isValid()) {
      return validation;
    }
  }

  return createInputValidation({});
}
