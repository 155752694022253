/// <reference types="vite/client" />
import type { Event, EventHint } from '@sentry/browser';
import { isProd } from './utils/is-prod';

let Sentry: any = null;

type CaptureContext = { extra: Record<string, any> };

export default function createSentry() {
  if (Sentry) {
    return;
  }

  import('@sentry/browser').then((sentry) => {
    Sentry = sentry;

    console.log(
      'Sentry DSN',
      import.meta.env.VITE_SENTRY_LARAVEL_DSN,
      'env',
      import.meta.env.VITE_APP_ENV
    );

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
      environment: import.meta.env.VITE_APP_ENV,
      tunnel: '/api/s',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          blockAllMedia: true,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: isProd ? 0.1 : 1,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate:
        isProd ? 0.1 : 1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      // Set the depth of reported errors
      normalizeDepth: 5,
    });

    setTimeout(() => {
      if (navigator.onLine) {
        let offlineErrors = localStorage.getItem('offline_errors');
        if (offlineErrors) {
          offlineErrors = JSON.parse(offlineErrors);

          console.error(offlineErrors);

          captureException(offlineErrors);

          localStorage.removeItem('offline_errors');
        }
      }
    }, 2000);
  });
}

export const captureException = (
  exception: any,
  captureContext?: CaptureContext
) => {
  console.error('captureException', exception, captureContext);
  if (Sentry) {
    return Sentry.captureException(exception, captureContext);
  }

  try {
    import('@sentry/browser').then((sentry) => {
      sentry.captureException(exception, captureContext);
    });
  } catch (e) { }
};
export const captureMessage = (
  message: string,
  captureContext?: CaptureContext
) => {
  console.info('captureMessage', message, captureContext);
  if (Sentry) {
    return Sentry.captureMessage(message, captureContext);
  }

  try {
    import('@sentry/browser').then((sentry) => {
      sentry.captureMessage(message, captureContext);
    });
  } catch (e) { }
};
export const captureEvent = (
  event: Event,
  eventHint?: EventHint | undefined
) => {
  console.info('captureEvent', event, eventHint);
  if (Sentry) {
    return Sentry.captureEvent(event, eventHint);
  }

  try {
    import('@sentry/browser').then((sentry) => {
      sentry.captureEvent(event, eventHint);
    });
  } catch (e) { }
};
export const setContext = (
  name: string,
  context: {
    [key: string]: any;
  } | null
) => {
  if (Sentry) {
    return Sentry.setContext(name, context);
  }

  try {
    import('@sentry/browser').then((sentry) => {
      sentry.setContext(name, context);
    });
  } catch (e) { }
};
