<template>
  <div>
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup class="text-danger" v-if="field.is_required">*</sup>
      <i class="fas fa-eraser clickable" @click="clearSignature" />
    </label>

    <div class="position-relative">
      <vueSignature
        v-if="isGather"
        ref="signature"
        :key="placeholderImage"
        :sigOption="option"
        :class="[
          'signature-form',
          {
            'border-danger': field.is_required && !inputValue.value,
          },
        ]"
        :defaultUrl="placeholderImage"
        w="280px"
        h="100px"
        @endStroke="finishSignatureDraw"
      />
      <template v-else>
        <img v-if="placeholderImage" class="w-100" :src="placeholderImage" />
        <div class="alert alert-warning mb-0">
          <i class="fas fa-exclamation-triangle"></i>
          <span class="ms-2"> Please edit signatures on Gather.</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import vueSignature from 'vue-signature';
import useIsGather from '../../composables/useIsGather';

export default {
  props: {
    field: Object,
    inputValue: Object,
  },
  data: () => ({
    option: {
      penColor: 'rgb(0, 0, 0)',
      backgroundColor: 'rgb(255,255,255)',
    },
  }),
  components: {
    vueSignature,
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
    }),
    isGather() {
      return useIsGather();
    },
    placeholderImage() {
      const value = this.inputValue.value;

      if (!value) return;

      if (value.includes('base64')) {
        return value;
      }

      return '/api/images/value/' + this.project.project_id + '/' + value;
    },
  },
  methods: {
    clearSignature() {
      this.$refs.signature.clear();

      this.$root.$emit('updateInputValue', {
        inputValue: {
          ...this.inputValue,
          value: null,
        },
        field: this.inputValue.template_field_id,
        sectionIndex: this.inputValue.template_section_index,
        templateTabId: this.inputValue.template_tab_id,
      });
    },
    finishSignatureDraw() {
      this.$root.$emit('updateInputValue', {
        inputValue: {
          ...this.inputValue,
          value: this.$refs.signature.sig.toDataURL(),
        },
        field: this.inputValue.template_field_id,
        sectionIndex: this.inputValue.template_section_index,
        templateTabId: this.inputValue.template_tab_id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.signature-form {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>
