import { Checklist } from './company-checklist';
import { Location } from './project';
import type { User } from './user';
import { captureMessage } from './sentry';
import { CompanyRoleProfile } from './company-role-profile';
import { UnitConversion } from './utils/unit-conversion';

export type DefaultTeamRoleKey = 'default' | 'external_user' | COMPANY_ROLE;
export type DefaultTeamRoleFullback = null;
export type DefaultTeamRoleFullAccessDefault = null;
export type DefaultTeamRoleFullAccessExplicit = -1;

/**
 * For default key:
 *  null means Full Access
 *  -1 is invalid for default key
 * If other role keys, e.g. external_user, account_manager:
 *  `null` means fallback to default
 *  -1 means Full Access for non-default keys
 *
 * 0 is invalid
 * 1+ is composer_user_profile.id
 */
export type TeamRoleDefaults = Record<
  'default',
  DefaultTeamRoleFullAccessDefault | CompanyRoleProfile['id']
> &
  Record<
    'external_user' | COMPANY_ROLE,
    | DefaultTeamRoleFullback
    | DefaultTeamRoleFullAccessExplicit
    | CompanyRoleProfile['id']
  >;

export interface Company {
  id: number;
  enad_ref: string;
  company_name: string;
  location_id: number;
  managers?: User[];
  users?: User[];
  location?: Location;
  checklists: Checklist[];

  logo_dark: null | string;
  logo_light: null | string;

  enviro_template_id: number;
  subscription_is_trial: boolean;
  subscription_tier: number;
  subscription_start: string | null;
  subscription_until: string | null;
  subscription_auto_lockout?: boolean;
  subscription_user_limit: null | number;
  subscription_project_limit: null | number;
  subscription_annual_project_limit: null | number;
  subscription_storage_limit: null | number;
  subscription_storage_annual_limit: null | number;
  subscription_storage_enforce_limit: boolean;
  subscription_project_enforce_limit: boolean;
  project_one_off_charge: null | number;
  /** Storage in MB as float, or not yet calculated when null */
  storage_usage: number | null;

  is_display_enviro_basis_on: boolean;
  use_uploaded_chemical_title: boolean;
  keep_conservative_eql_on_upload: boolean;
  is_force_local_gather_offline_mode: boolean;
  upload_title_structures: CompanyUploadTitleStructure[] | null;

  map_color_palette: string[] | null;
  unit_conversions: UnitConversion[];

  updated_at: string;
  created_at: string;
}

export type CompanyUploadTitleStructure = {
  id: string;
  type: 'enviro' | 'dupe-trip';
  structure: any[];
  regex_string: string | null;
};

export const COMPANY_ROLES = {
  ROLE_ACCOUNT_MANAGER: 'account-manager',
  ROLE_GATHER_APP_REVIEWER: 'gather.app-reviewer',
  ROLE_ACCESS_CONFIDENTIAL_PROJECTS: 'projects.confidential.access',
  ROLE_FORBIDDEN_ACCESS_MAPS_NEARMAP: 'maps.nearmap.access.forbidden',
  ROLE_FORBIDDEN_ACCESS_MAPS_METROMAP: 'maps.metromap.access.forbidden',
} as const;

export type COMPANY_ROLE = (typeof COMPANY_ROLES)[keyof typeof COMPANY_ROLES];

export function isCompanyManager(authUser: User): boolean {
  if (!authUser.company_roles) {
    captureMessage('Cannot verify user that has no company roles data');
    return false;
  }
  return authUser.company_roles.some(
    (r) => r.role === COMPANY_ROLES.ROLE_ACCOUNT_MANAGER
  );
}

export function hasCompanyRole(authUser: User, role: COMPANY_ROLE): boolean {
  if (!authUser.company_roles) {
    captureMessage(
      'Cannot verify ' + role + ' user that has no company roles data'
    );
    return false;
  }
  return authUser.company_roles.some((r) => r.role === role);
}

export function formatCompanyRole(role: COMPANY_ROLE) {
  return String(role)
    .split(/[_-]/)
    .map((r) => r.at(0)?.toUpperCase() + r.slice(1))
    .join(' ')
    .split(/[.]/)
    .map((r) => r.at(0)?.toUpperCase() + r.slice(1))
    .join(' - ');
}

export interface Subscription {
  users_count: number;
  projects_count: number;
  projects_total_count: number;
  storage_usage: number;
  subscription_user_limit: null | number;
  subscription_project_limit: null | number;
  subscription_annual_project_limit: null | number;
  subscription_storage_limit: null | number;
  subscription_storage_annual_limit: null | number;
  subscription_storage_enforce_limit: boolean;
  subscription_project_enforce_limit: boolean;
  subscription_start: string;
  subscription_until: string | null;
  subscription_tier: number;
  subscription_auto_lockout: boolean;
  subscription_is_trial: boolean;
  project_one_off_charge: number;
}
