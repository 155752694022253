<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { ErrorBag } from '../business-model/form';
import { usePublicApi } from '../api';
import AutoComplete from './AutoComplete.vue';
import { Address } from '../project';
import Spinner from './Spinner.vue';

const props = withDefaults(
  defineProps<{
    latitude?: number;
    longitude?: number;
    error?: ErrorBag;
    addressData?: Address;
    country?: string | null;
    required?: boolean;
    mustHaveCoordinates: boolean;
  }>(),
  {}
);

const isOnline = computed(() => {
  return window.navigator.onLine;
});

const manualMode = ref(!isOnline.value);
const fullAddress = ref('');
const address_street = ref('');
const address_street_number = ref('');
const address_locality = ref('');
const address_city = ref('');
const address_state = ref('');
const address_country = ref('');
const address_postcode = ref('');
const google_place_id = ref<null | string>(null);
const latitude = ref('');
const longitude = ref('');
const isLoadingLatLng = ref(false);

const emit = defineEmits(['locationSelected']);

defineExpose({ getAddress });

watch(
  () => props.addressData,
  (val) => {
    if (val) {
      setAddressData(val);
    }
  }
);

const additionalQueryParams = computed(() => {
  if (!props.country) {
    return null;
  }

  return 'components=country:' + props.country;
});

function getAddress(): Address {
  return {
    full_address: fullAddress.value,
    latitude: latitude.value,
    longitude: longitude.value,
    google_place_id: google_place_id.value,

    address_street: address_street.value,
    address_locality: address_locality.value,
    address_city: address_city.value,
    address_state: address_state.value,
    address_country: address_country.value ?? props.country,
    address_postcode: address_postcode.value,
  };
}

function setAddressData(address: Address) {
  if (!address) {
    return;
  }
  fullAddress.value = address.full_address;
  latitude.value = address.latitude;
  longitude.value = address.longitude;
  google_place_id.value = address.google_place_id;

  address_street.value = address.address_street;
  address_locality.value = address.address_locality;
  address_city.value = address.address_city;
  address_state.value = address.address_state;
  address_country.value = address.address_country;
  address_postcode.value = address.address_postcode;
  isLoadingLatLng.value = false;
}

function setAddress(data) {
  const { value: _fullAddress } = data;
  if (_fullAddress === null) {
    fullAddress.value = '';
    latitude.value = '';
    longitude.value = '';
    google_place_id.value = null;
    address_street.value = '';
    address_locality.value = '';
    address_city.value = '';
    address_state.value = '';
    address_country.value = '';
    address_postcode.value = '';
    emit('locationSelected', getAddress());
    return;
  }

  fullAddress.value = _fullAddress;

  const { data: response } = data;
  if (response != null) {
    isLoadingLatLng.value = true;

    usePublicApi()
      .get(
        '/geocode?q=' +
          fullAddress.value +
          '&componentRestrictions=country:' +
          props.country
      )
      .then((response) => {
        if (response.data.results.length > 0) {
          const result = response.data.results[0];
          latitude.value = result.geometry.location.lat;
          longitude.value = result.geometry.location.lng;

          address_street_number.value =
            address_street.value =
            address_locality.value =
            address_city.value =
            address_state.value =
            address_country.value =
            address_postcode.value =
              '';

          google_place_id.value = null;

          const lookup = {
            street_number: address_street_number,
            route: address_street,
            administrative_area_level_1: address_state,
            country: address_country,
            postal_code: address_postcode,
          };
          for (let component of result.address_components) {
            for (let type in lookup) {
              if (component.types.includes(type)) {
                lookup[type].value = component.long_name;
                break;
              }
            }
          }

          address_locality.value =
            result.address_components.length > 4
              ? result.address_components[2].long_name
              : null;
          address_city.value =
            result.address_components.length > 4
              ? result.address_components[3].long_name
              : null;
          google_place_id.value = result.place_id;

          address_street.value = (
            (address_street_number.value ?? '') +
            ' ' +
            address_street.value
          ).trim();
          emit('locationSelected', getAddress());
          isLoadingLatLng.value = false;
          return;
        }

        latitude.value = latitude.value ?? '';
        longitude.value = longitude.value ?? '';
      })
      .finally(() => {
        isLoadingLatLng.value = false;
      });
  }
}

onMounted(() => {
  if (props.addressData) {
    if (!props.addressData.full_address) {
      manualMode.value = true;
    }

    setAddressData(props.addressData);
  }
});
</script>

<template>
  <div>
    <AutoComplete
      v-if="isOnline"
      :queryUrl="`/address-autocomplete?${additionalQueryParams}`"
      @changeValue="setAddress"
      :previousValue="manualMode ? address_street : addressData?.full_address"
      placeholder="Enter a street address"
      container="predictions"
      displayIndex="description"
      :selectOnExit="!manualMode"
      :shortenByChar="manualMode ? ',' : null"
      :dontDisable="manualMode"
    />
    <div v-if="isLoadingLatLng" class="d-flex">
      <Spinner />
    </div>

    <div v-if="manualMode" class="mt-2">
      <div class="mb-2">
        <label for="projectName" class="form-label">
          Street <sup class="text-danger">*</sup>
        </label>
        <input
          class="form-control"
          v-model="address_street"
          @input="emit('locationSelected', getAddress())"
        />
      </div>
      <div class="mb-2">
        <label for="projectName" class="form-label">
          Suburb <sup class="text-danger">*</sup>
        </label>
        <input
          class="form-control"
          v-model="address_locality"
          @input="emit('locationSelected', getAddress())"
        />
      </div>
      <div class="mb-2">
        <label for="projectName" class="form-label">
          City / Township <sup class="text-danger">*</sup>
        </label>
        <input
          class="form-control"
          v-model="address_city"
          @input="emit('locationSelected', getAddress())"
        />
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <label for="projectName" class="form-label">
            State / Region <sup class="text-danger">*</sup>
          </label>
          <input
            class="form-control"
            v-model="address_state"
            @input="emit('locationSelected', getAddress())"
          />
        </div>
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <label for="projectName" class="form-label">
            Post Code <sup class="text-danger">*</sup>
          </label>
          <input
            class="form-control"
            v-model="address_postcode"
            required
            @input="emit('locationSelected', getAddress())"
          />
        </div>
      </div>
    </div>
  </div>
</template>
