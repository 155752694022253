import axios, { AxiosRequestConfig, AxiosResponse, type Axios } from 'axios';
import { DATANEST_URL } from './env';
import auth from './auth';

export const client: Axios = createAuthorizedClient();

function createAuthorizedClient(): Axios {
  const client = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  client?.interceptors.request.use((config) => {
    const token = auth.getToken();
    config.headers ||= {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.error('Auth api used without token!', config);
      delete config.headers.Authorization;
    }
    return config;
  });

  if (!client && import.meta.env.MODE !== 'test') {
    throw new Error('No client, bad mock of axios.create?');
  }

  return client;
}

/** For public access to Hub's API */
export const clientPublic: Axios = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

/** For external requests, e.g. service layers */
export const externalClient = axios.create();
if (externalClient?.defaults?.headers?.common) {
  delete externalClient.defaults.headers.common['Authorization'];
}

type Interceptors = {
  doBeforeRequestIsSent:
  | ((
    value: AxiosRequestConfig<any>
  ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>)
  | undefined;
  handleRequestError: ((error: any) => any) | undefined;
  doAfterResponseIsReady:
  | ((
    value: AxiosResponse<any, any>
  ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
  | undefined;
  handleResponseError: ((error: any) => any) | undefined;
};

export function setInterceptors(interceptors: Interceptors) {
  const {
    doBeforeRequestIsSent,
    handleRequestError,
    doAfterResponseIsReady,
    handleResponseError,
  } = interceptors;

  client.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  client.interceptors.response.use(doAfterResponseIsReady, handleResponseError);

  axios.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  axios.interceptors.response.use(doAfterResponseIsReady, handleResponseError);
}

export default function useApi(): Axios {
  return client;
}

export function usePublicApi(): Axios {
  return clientPublic;
}

// Get the base url with fallbacks for both Gather and Datanest
export function getBaseUrl() {
  // explicit hub api for future use.
  if (import.meta.env?.VITE_HUB_API_URL) {
    return import.meta.env.VITE_HUB_API_URL;
  }
  if (!DATANEST_URL) {
    throw new Error('No DATANEST_URL found, missing env configuration?');
  }
  // If running in local container
  if (
    typeof window !== 'undefined' &&
    (window.location?.host || '').includes('nginx-datanest')
  ) {
    return '/api';
  }
  return `${DATANEST_URL}/api`;
}
